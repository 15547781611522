
/* join-us-section*/
.join-us-section{
    margin-bottom: 7rem;
}
.our-mission-card{
    margin: auto;
    position: relative;
    height: 20rem;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 3rem;
    color: #fff;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
}

.join-us-missions-container{
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;

}


.our-mission-card-body{
    position: absolute;
    bottom: 1rem;
    padding: 2rem;
    
    
}

.mission-card-link{
    border-bottom: 3px solid var(--color-primary-yellow-3);
    font-weight: bold;
    font-size: 23px;
     font-family: Fira Sans Condensed,sans-serif;
}
.mission-card-span{
    border-bottom: 3px solid var(--color-primary-yellow-3);
    font-weight: bold;
    font-size: 23px;
}
/* End join-us-section*/