.nav-button{
  border-radius: 100rem!important;
    font-weight: bold!important;
    font-size: 0.9rem!important;
    padding: 0.2rem 0.7rem!important;
    color: #000!important;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5)!important;
    border: solid 3px transparent!important;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green))!important;
    background-origin: border-box!important;
    background-clip: content-box, border-box!important;
    box-shadow: 2px 1000px 1px #fff inset!important;
    font-family: Fira Sans Condensed,sans-serif!important;
}
.nav-button:hover {
    box-shadow: none!important;
    color: #fff!important;
  }
button.app-button {
    border-radius: 100rem;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    color: #000;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
    font-family: Fira Sans Condensed,sans-serif;
  }
  
button.app-button:hover {
    box-shadow: none;
    color: #fff;
  }

button.donate-button{
    border-radius: 100rem;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    color: #fff;
    background-color: var(--color-primary-yellow-2);
}
button.donate-button:hover{
    background-color: var(--color-primary-yellow-3);

}



@media only screen and (max-width: 992px) {
  
  button.donate-button ,button.app-button{
    font-size: 10px;
    padding: 0.1rem 0.5rem;
  }
}


.app-button-rapport {
  border-radius: 100rem !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  padding: 0.5rem 1.5rem !important;
  color: white !important;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5)!important;
  border: solid 3px transparent!important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green))!important;
  background-origin: border-box!important;
  background-clip: content-box, border-box!important;
  box-shadow: 2px 1000px 1px #fff inherit!important;
}

.app-button-rapport:hover {
  
  box-shadow: 2px 1000px 1px #fff inset!important;
  color: black!important;
}

