.region {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border: 1px solid #000;
    vertical-align: middle;
   
}
.region-legend{
    font-size: 12px;
    margin: 10px;
}