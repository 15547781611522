
.header-card-main{
    top: -7rem;
    right: 5rem;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
}

.hearder-card{
    position: relative;
    text-transform: uppercase;
    font-weight: bolder;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green));
    background-origin: border-box;
    padding: 2.5rem;
    border-radius: 2rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
.header-card-main{
    top: 0rem;
    right: 0rem;
    visibility: hidden;
    display: none;
    position: relative;
    margin-top: 200px;
}

}

.hearder-card h2,.hearder-card .row p:first-child{
    font-size: 1.5rem;
    font-weight: bolder;
    color: #fff;
}


.hearder-card .row p:last-child{
    font-weight: bolder;
    
}

.sigma_portfolio-item{
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .sigma_portfolio-item img{
    width: 100%;
  }
  .sigma_portfolio-item span{
    color: #fff;
    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }
  
  .sigma_portfolio-item .sigma_portfolio-item-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .3s;
    padding: 20px;
  }
  .sigma_portfolio-item:hover .sigma_portfolio-item-content{
    background-color: rgba(0,0,0,.4);
  }
  .sigma_portfolio-item .sigma_portfolio-item-content-inner{
    margin-top: auto;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }
  .sigma_portfolio-item .sigma_portfolio-item-content-inner h5{
    margin: 0 0 10px;
  }
  .sigma_portfolio-item .sigma_portfolio-item-content-inner p{
    margin: 0;
    color: #f4f4f4;
  }
  .sigma_portfolio-item .sigma_portfolio-item-content-inner h5 a{
    color: #fff;
  }
  .sigma_portfolio-item .sigma_portfolio-item-content-inner h5 a:hover{
    color: #bf0a30;
  }
  .sigma_portfolio-item .sigma_portfolio-item-content i{
    font-size: 15px;
    position: absolute;
    top: 20px;
    left: 20px;
    color: #fff;
    transition: .3s;
    z-index: 3;
    background-color: #bf0a30;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
  }
  
  .sigma_portfolio-item:hover .sigma_portfolio-item-content i{
    opacity: 1;
    visibility: visible;
    bottom: 20px;
  }
  
  .sigma_portfolio-item:hover  span,
  .sigma_portfolio-item:hover .sigma_portfolio-item-content-inner{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .filter-items{
    position: relative;
    max-width: 700px;
    margin: 0 auto 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
  }
  .filter-items.left{
    margin-left: 0;
  }
  .filter-items h5{
    position: relative;
    transition: .3s;
    padding: 20px 40px;
    font-size: 14px;
    color: #777;
    border-bottom: 3px solid transparent;
    margin: 0;
    cursor: pointer;
  }
  .filter-items h5 + h5::before{
    content: '';
    width: 2px;
    height: 15px;
    background-color: #efefef;
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }
  .filter-items h5.active{
    border-bottom-color: #bf0a30;
    color: #bf0a30;
  }
  .filter-items h5:not(.active):hover{
    color: #020202;
  }
  
/* Our fights section */
.our-fights-section {
    margin-top: 5rem;
}

.our-fights-section h1{
    font-size: 45px;
    display: block;
    color: #000000;
    font-weight: bolder;
    text-transform: uppercase;
    line-height: 1.4;
}

.our-fights-card{
    position: relative;
    height: 34rem;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 3rem;
    color: #fff;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
}


.our-fight-card-body{
    position: absolute;
    bottom: 1rem;
    padding: 2rem;
}


.our-fight-card-body h3{
    font-size: 25px;
    display: block;
    color: #ffffff;
    font-weight: bolder;
    line-height: 1.4;
}


/* end Our fights section */


/* Join us section */

.join-us-section{
    margin-top: 6rem;

}

.join-us-section .join-us-content {
    bottom: 0rem;
    height: 38rem;
    background-image:
    linear-gradient(to bottom, rgba(146, 139, 139, 0.452), rgba(0, 0, 0, 0.904)),
    url(assets/images/equipe2.jpeg);
    background-size: cover;
    padding: 4rem;
    margin-top: 3rem;
    color: #fff;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
}

.join-us-section .join-us-content h2{
    font-size: 55px;
    margin-top: 3rem;
}

.join-us-actions {
    margin-top: 3rem;
}

.join-us-actions div h3 {
    font-weight: bolder;
    margin-bottom: 1.5rem;
}


.join-us-actions >div:first-child{
    border-radius: 1rem;
    transition:all 0.5s ease-in-out;
    padding: 1rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    -webkit-transition:all 0.5s ease-in-out;
    -moz-transition:all 0.5s ease-in-out;
    -ms-transition:all 0.5s ease-in-out;
    -o-transition:all 0.5s ease-in-out;
}

.join-us-actions >div:last-child{
    background-color: var(--color-primary-yellow-2);
    border-radius: 1rem;
    transition:all 0.5s ease-in-out;
    padding: 2rem 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    -webkit-transition:all 0.5s ease-in-out;
    -moz-transition:all 0.5s ease-in-out;
    -ms-transition:all 0.5s ease-in-out;
    -o-transition:all 0.5s ease-in-out;
}
/* End Join us section */


.live_terrain{
   margin-left: 20px;
    font-weight: 900px;
    background: linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green));
    -webkit-background-clip:text ;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }