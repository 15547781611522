
.header{

    background-size:cover;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0.5rem;
    padding-top: 100px;
    height: 600px;
    margin-top: 1rem;
    color: #fff;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
}


.header2{

    background-image:
    linear-gradient(to left, rgba(146, 139, 139, 0.452), rgba(0, 0, 0, 0.904)),
    url(assets/images/momentum.png);
    background-size:cover;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0.5rem;
    padding-top: 100px;
    height: 200px;
    margin-top: 12rem;
    color: #fff;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;

}






.header h1{
    font-size: 45px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bolder;
}


.header p{
    margin-bottom: 5rem;
    margin-top: 2rem;
}