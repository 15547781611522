
.actions-list-container{
    margin-top: 7rem;
    margin-bottom: 8rem;
}

.actions-list-container h1{
    font-weight: bolder;
    margin-bottom: 1rem;
}


.actions-list-container>div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 3rem;
}


.title_founder{

    /* background-color: #e6ba04; */
    height: 8px;
    /* width: 250px; */
    width: auto !important;
    
}

.title_founder2{

    background-color: #e6ba04;
    height: 8px;
    width: 200px;
    
}

.title_founder5{

   font-weight: 900;
    
}

.title_founder3{

    background-color: #e6ba04;
    height: 8px;
    width: 120px;
    
}



.represent{
    border-radius: 30px;
}

.img-fluid{
 height: 250px;
}


.hauteur_represent{
    height: 150px;
}

.margin_founder{
    margin-top: 20px;
}

.sign_founder{

    font-weight: 900;
    float: right;
}

.projet-header{
    margin-top: 1rem;
}