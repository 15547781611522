
/* main*/
.span-colored{
    color: var(--color-primary-yellow-3);
    font-weight: bold;
}


/*  details-actions header  */
.details-actions-header{
    background-image:
    linear-gradient(to bottom, rgba(146, 139, 139, 0.452), rgba(0, 0, 0, 0.849), rgb(0, 0, 0)),
    url(assets/images/logo_ong_eicf.jpg);
    padding: 4rem;
    margin-top: 12rem;
    color: #fff;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
    height: 300px;
}

.details-actions-header h1{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 35px;
}

.details-actions-text{
    margin-top: 3rem;
    margin-left: 10rem;
}

.details-actions-header .details-actions-text p{
    margin-top: 2rem;
}
/* End details-actions header */



/* our-mission-container */
.our-mission-container{
    margin-top: 5rem;
}

.our-mission-container h2{
    font-weight: bold;
}

.our-mission-container-img-content img{
    border-radius: 5rem;
    padding: 1rem;
    font-size: 1rem;
    color: #000;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 5px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
    -webkit-border-radius: 5rem;
    -moz-border-radius: 5rem;
    -ms-border-radius: 5rem;
    -o-border-radius: 5rem;
}
/* End our-mission-container */


/* other-mission-container */

.other-mission-container{
    margin-top: 5rem;
}

.other-mission-container>div h2{
    font-weight: bold;
    color: #000;
}


.other-mission-card-container{
    margin-top: 3rem;
}

.other-mission-card-content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    padding: 2rem;
    background-color: var(--color-primary-green);
    border-radius: 2rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
    transition: all 1s ease-in-out;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 5px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px var(--color-primary-green) inset;
}

.other-mission-card{
    position: relative;
    height: 34rem;
    width: 100%;
    padding: 1.5rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 1s ease-in-out;
    color: #ffffffbe;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
}


.other-mission-card>div{
    position: absolute;
    bottom: 0;
}

/* End other-mission-container */


/*  other-mission-indicator */
.other-mission-indicator{
    margin-top: 2rem;
}

.other-mission-indicator >div{
    text-align: center;
    padding: 2rem 0  1rem 0;
    border-radius: 5rem;
    -webkit-border-radius: 5rem;
    -moz-border-radius: 5rem;
    -ms-border-radius: 5rem;
    -o-border-radius: 5rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 5px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px var(--color-primary-green-2) inset;

}   
/* End other-mission-indicator */