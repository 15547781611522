

/*  specific-objectif-header  */
.specific-objectif-header{
    background-image:
    linear-gradient(to bottom, rgba(146, 139, 139, 0.452), rgba(0, 0, 0, 0.849), rgb(0, 0, 0)),
    url(assets/images/logo_ong_eicf.jpg);
    padding: 4rem;
    margin-top: 5rem;
    color: #fff;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
}

.specific-objectif-header h1{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 35px;
}

.specific-objectif-header-text{
    margin-top: 3rem;
    margin-left: 10rem;
}

.specific-objectif-header .specific-objectif-header-text p{
    margin-top: 2rem;
}
/* End specific-objectif-header  */

/* our-worth*/
.our-worth{
    margin-top: 4rem;
}

.our-worth-mission-indicator{
    margin-top: 2rem;
}

.margin_who_us{
    margin-top: 10rem;
}

.our-worth-mission-indicator >div{
    text-align: center;
    padding: 2rem 5rem;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 5px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px var(--color-primary-green-2) inset;
}   

.our-worth-mission-indicator-style{
    width: 1.2rem;
    display: block;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(to bottom, var(--color-primary-yellow), var(--color-primary-green));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff unset;
}


.our-worth-mission-indicator-text{
    text-align: start;
    padding-left: 1rem;
}

.show-more{
    margin-top: 5rem;
}

.show-more-content{
    background-color: var(--color-primary-green);
    text-align: center;
    padding: 1rem 3rem;
}

.show-more-report-button{
    margin-top: 1rem;
    text-align: center;
    background-color: var(--color-primary-yellow-3);
    padding: 2rem;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 30px;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}
/* End our-worth*/

.who-us-items:hover{
    color: var(--color-primary-yellow-3) !important;
    cursor: pointer;
}