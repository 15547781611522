.eicf_start{
    margin-top:165px
}
.contact_zone{
    background: var(--bg-gradiant-primary);
}
.contact_field >input{
    
    color:white
}
.contact_field ::placeholder  {
    color:white
}
.contact-ground{
    background-image: url("https://cdn.pixabay.com/photo/2016/11/04/12/43/child-1797637_1280.jpg");
    height: 350px;
    
}
.contact-text{
    color:white;
    padding-top: 10%;
    font-family: Fira Sans Condensed,sans-serif;
}