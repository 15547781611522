
.activity-header{

    background-image:
    linear-gradient(to bottom, rgba(146, 139, 139, 0.452), rgba(0, 0, 0, 0.849), rgb(0, 0, 0)),
    url(assets/images/enfant.jpg);
    padding: 4rem;
    margin-top: 1rem;
    color: #fff;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
    background-repeat: no-repeat;
    background-size: cover;
  

}

.actiivty-items-container{
    display: flex;
    column-gap: 20px;
    flex-direction: row;
}

.activity-items{
    text-align: center;
    font-weight: bolder;
}

.activity-number{
    font-weight: bold;
    color: var(--color-primary-yellow-3);
}

.activity-unit{
    font-size: 12px;
    font-weight: bold;
    color: #fff;
}

.ctivity-items p{
    font-weight: bolder;
    font-size: 30px;
}

.projet-header-2{
    background-image:
    linear-gradient(to bottom, rgba(146, 139, 139, 0.452), rgba(0, 0, 0, 0.849), rgb(0, 0, 0)),
    url(assets/images/logo_ong_eicf.jpg);
    padding: 4rem;
    backdrop-filter: blur(50px);
    color: #fff;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
    margin-top: 12rem!important;
}

 /* Styles pour les écrans de largeur inférieure à 768px (par exemple, mobile) */
 @media (max-width: 767px) {
    .actiivty-items-container{
        display: flex;
        column-gap: 20px;
        flex-direction: row;
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
    }
  }