@import url("~leaflet/dist/leaflet.css");

:root{
  --color-primary-green: #76b72a;
  --color-primary-green-2: #f2ffe8;
  --color-primary-yellow: #e6ba04;
  --color-primary-yellow-2: #ffcc33;
  --color-primary-yellow-3: #f8a655;
  --bg-gradiant-primary:  linear-gradient(90deg, var(--color-primary-yellow)  0%,  var(--color-primary-green) 100%);
};
  

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}



body {
    background-color: #fff;
    font-family: Source Sans Pro,sans-serif;
    color: #323c47;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


