
.footer .contacts p span {
    text-transform: capitalize;
    font-weight: bolder;
    font-family: Fira Sans Condensed,sans-serif;
}

.footer .links .who-us p{
    color: var(--color-primary-yellow-3);
    font-weight: bolder;
    font-family: Fira Sans Condensed,sans-serif;
}



.footer .links {
    text-align: center;
    font-family: Fira Sans Condensed,sans-serif;
}


.footer .links .who-us ul{
    margin: 0;
    padding: 0;
    display: block;
    text-align: center;
    list-style: none;
    font-family: Fira Sans Condensed,sans-serif;
}


.footer .links .who-us ul li{
    margin: 1rem 0rem;
}

.footer .informations{
    background: var(--bg-gradiant-primary);
    padding: 3rem;
    border-radius: 5%;
    -webkit-border-radius: 5%;
    -moz-border-radius: 5%;
    -ms-border-radius: 5%;
    -o-border-radius: 5%;
    color:white;
    font-family: Fira Sans Condensed,sans-serif;
}

.footer .informations .inputs{
    background-color: var(--color-primary-yellow-3);
    color:black;
    display: flex;
    border-radius:5000px;
    -webkit-border-radius:5000px;
    -moz-border-radius:5000px;
    -ms-border-radius:5000px;
    -o-border-radius:5000px;
    
}

.footer .informations .inputs input{
    border-radius:5000px;
    height: 2.7rem;
    padding-left: 1rem;
    -webkit-border-radius:5000px;
    -moz-border-radius:5000px;
    -ms-border-radius:5000px;
    -o-border-radius:5000px;
}

.footer .informations .inputs button{
    display: block;
    text-transform: capitalize;
    color: #fff;
    font-weight: bolder;
    border-radius:5000px ;
    font-size: 0.8rem;
    -webkit-border-radius:5000px ;
    -moz-border-radius:5000px ;
    -ms-border-radius:5000px ;
    -o-border-radius:5000px ;
}

.created_footer
{
    color: orange;
}

.text-justify{
    text-align:justify;
}
.footer-field{
    font-family: Fira Sans Condensed,sans-serif;
}
.footer-field:hover{
    background-image: var(--bg-gradiant-primary) !important; /* Remplacez les couleurs par celles de votre choix */
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  display: inline-block ! important;
}


/* Styles pour les écrans de largeur inférieure à 768px (par exemple, mobile) */
@media (max-width: 767px) {
    .footer .informations .inputs{
  
        color:black;
        display: flex;
        border-radius:5000px;
        -webkit-border-radius:5000px;
        -moz-border-radius:5000px;
        -ms-border-radius:5000px;
        -o-border-radius:5000px;
        
    }
    .footer .informations .inputs button{
        background-color: var(--color-primary-yellow-3);
        display: block;
        text-transform: capitalize;
        color: #fff;
        font-weight: bolder;
        border-radius:5000px ;
        font-size: 0.8rem;

        -webkit-border-radius:5000px ;
        -moz-border-radius:5000px ;
        -ms-border-radius:5000px ;
        -o-border-radius:5000px ;
    }
  }