.eicf_start{
    margin-top:250px
}
.don_header{
    color: white !important;;
    background: var(--bg-gradiant-primary);
    font-size: 18px;
}

.don_current{
    padding-top: 6px;
    background: var(--bg-gradiant-primary);
    color: white !important;;
    font-size: 18px;
}
.don_button{
    background: var(--bg-gradiant-primary) !important;
    color: white !important;;
}
.don_button:hover {
    background: rgba(46, 121, 56, 0.575)!important;;
    color: var(--bg-gradiant-primary) !important;
}

.don-ground{
    background-image: url("https://cdn.pixabay.com/photo/2016/11/04/12/43/child-1797637_1280.jpg");
    height: 350px;
    
}
.don-text{
    color:white;
    padding-top: 10%;
    font-family: Fira Sans Condensed,sans-serif;
}