.hide_laps {
    display: none!important;
  }
.news_eicf {
    color:white;
    background: var(--bg-gradiant-primary);
    font-size: 18px;
    font-family: Fira Sans Condensed,sans-serif;
    
}
.app-nav{
    position: fixed;
    background-color: #fff;
    z-index: 100;
   top: 0 !important;
  
}
.navbar .ong-eicf-logo{
    height: 7rem;
    width: 7rem;
 }

 .navbar  ul.nav-header-ul{
    list-style: none ;
    text-transform: uppercase;
    display: flex;
    font-weight: bolder;
}

.navbar ul.nav-header-ul li{
    margin: 0 5px;
    font-weight: bolder;
    font-size: 11px;
}

.navbar .navbar-divider{
    display: block;
    height: 5px;
   
    margin-left: 10px!important;
    background: var(--bg-gradiant-primary);
}

.nav-button-container{
    margin-top: 1rem;
}


.nav-drawer-button-container,
div.nav-other-button-phone
{
    
    display: none !important;
}



.nav-other-button{
    display: block !important;
}
.nav-field{
    font-family: Fira Sans Condensed,sans-serif;
    font-size: 15px!important;
}
.nav-field-2{
    font-family: Fira Sans Condensed,sans-serif;
    font-size: 12px!important;
}
.nav-field-2:hover{
    background-image: var(--bg-gradiant-primary) !important; /* Remplacez les couleurs par celles de votre choix */
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.nav-field:hover{
    background-image: var(--bg-gradiant-primary) !important; /* Remplacez les couleurs par celles de votre choix */
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.nav-incon-span{
    background-image: var(--bg-gradiant-primary) !important; /* Remplacez les couleurs par celles de votre choix */
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

/* adaptation de l'ecran*/
/* @media only screen and (min-width: 1400px) {
    .navbar .ong-eicf-logo{
        height: 7rem;
        width: 7rem;
     }
    
     .navbar  ul.nav-header-ul{
        list-style: none ;
        text-transform: uppercase;
        display: flex;
        font-weight: bolder;
    }
    
    .navbar ul.nav-header-ul li{
        margin: 0 5px;
        font-weight: bolder;
        font-size: 11px;
    }

    .nav-button-container >div button{
        font-size: 12px ;
    }

    .nav-button-container{
        margin-top: 0.5rem;
    }

    .nav-field{
        font-family: Fira Sans Condensed,sans-serif;
        font-size: 9px!important;
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
    
  } */
  @media only screen and (max-width: 1600px) {
    .navbar .ong-eicf-logo{
        height: 7rem;
        width: 7rem;
     }
    
     .navbar  ul.nav-header-ul{
        list-style: none ;
        text-transform: uppercase;
        display: flex;
        font-weight: bolder;
    }
    
    .navbar ul.nav-header-ul li{
        margin: 0 5px;
        font-weight: bolder;
        font-size: 11px;
    }

    .nav-button-container >div button{
        font-size: 12px ;
    }

    .nav-button-container{
        margin-top: 0.5rem;
    }

    .nav-field{
        font-family: Fira Sans Condensed,sans-serif;
        font-size: 12px!important;
        padding-left: 10px!important;
        padding-right: 5px!important;
    }
    .nav-button{
        font-size: 12px!important;
    }
    
  }
@media only screen and (max-width: 1400px) {
    .navbar .ong-eicf-logo{
        height: 7rem;
        width: 7rem;
     }
    
     .navbar  ul.nav-header-ul{
        list-style: none ;
        text-transform: uppercase;
        display: flex;
        font-weight: bolder;
    }
    
    .navbar ul.nav-header-ul li{
        margin: 0 5px;
        font-weight: bolder;
        font-size: 11px;
    }

    .nav-button-container >div button{
        font-size: 12px ;
    }

    .nav-button-container{
        margin-top: 0.5rem;
    }

    .nav-field{
        font-family: Fira Sans Condensed,sans-serif;
        font-size: 12px!important;
        padding-left: 5px!important;
        padding-right: 5px!important;
    }
    .nav-button{
        font-size: 12px!important;
    }
    
  }


@media only screen and (max-width: 992px) {
    .navbar .ong-eicf-logo{
        height: 5rem;
        width: 5rem;
     }
    
     .navbar  ul.nav-header-ul{
        list-style: none ;
        text-transform: uppercase;
        display: flex;
        font-weight: bolder;
    }
    
    .navbar ul.nav-header-ul li{
        font-size: 8px ;
    }

    .nav-button-container >div button{
        font-size: 8px ;
    }

    .nav-button-container{
        margin-top: 0rem;
    }
    .nav-field{
        font-family: Fira Sans Condensed,sans-serif;
        font-size: 9px!important;
        padding-left: 5px!important;
        padding-right: 5px!important;
    }
    .nav-button{
        font-size: 9px!important;
    }
    
  }



@media only screen and (max-width: 768px) {
    .app-nav{
        box-shadow: 0 .5rem 1rem rgba(228, 227, 227, 0.582);
        top: 0;
        background: #fff;
    }
    .navbar{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .navbar .ong-eicf-logo{
        height: 5rem;
        width: 5rem;
     }


    .navbar .navbar-divider ,
    .navbar  ul.nav-header-ul,
    .navbar .nav-header-row ,
    .nav-button-container div.nav-button-menu,
    div.nav-other-button
    {
        display: none !important;
    }
    
    
    .navbar ul.nav-header-ul li{
        font-size: 8px ;
    }

    div.nav-other-button-phone{
        display: flex;
        justify-content: center !important;
    }

    div.nav-other-button-phone  > button{
        font-size: 16px;
        padding: 5px 10px;
        width: 100%;
    }


    .nav-button-container{
        margin-top: 0rem;
    }

    .nav-drawer-button-container,
    div.nav-other-button-phone
    {
        display: block !important;
    }
   
    
    
  }

