.global_header{
    margin-top: 164px;
    background-color: antiquewhite;
    height: 400px;
    margin-bottom: 2rem;
    margin-left: 0px!important;
  
    
}
.global_header_2{
   
    margin-top: 164px;
    background-color: antiquewhite;
    height: 300px;
    margin-bottom: 2rem;
}

.global_header_zone{
  
    height: 400px;
    display: flex;
 
}
.global_header_zone_2{
    padding: 10px;
    height: 400px;
    display: flex;

}

.global_header_2_1{
    color:white;
    font-weight: bold;
    /* background-color: #e6b9048e;
    background-color: #75b72a7c; */
    font-size: 25px;
    margin-top: 30px;
}
.global_header2{
   font-weight: bold;
    color: white;
    font-size: 20px;
}
.global_header_2_2{
    font-weight: bold;
     color: white;
     font-size: 15px;
 }
.header_first_link:hover{
    color:white,
    
}

.test{
    color:#bdbc9869;
}

/* Styles pour les écrans de largeur supérieure à 768px (par exemple, la version PC) */
@media (min-width: 768px) {
    .global_header1{
        
        /* background: linear-gradient(101deg, #e6ba04, #76b72a); */
        font-weight: bold;
        color:white;
        /* background-color: #e6b9048e;
        background-color: #75b72a7c; */
        font-size: 4.75rem!important;
        margin-top: 120px;
        /* -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
       
    
    }
  }
  
  /* Styles pour les écrans de largeur inférieure à 768px (par exemple, mobile) */
  @media (max-width: 767px) {
    .global_header1{
        /* background: linear-gradient(101deg, var(--color-primary-yellow), var(--color-primary-green)); */
        color:white;
        font-weight: bold;
        /* background-color: #e6b9048e;
        background-color: #75b72a7c; */
        font-size: 40px;
        margin-top: 120px;
        /* -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
    }
  }
  
  
  
  
  
  