
.card-action{
    transition:all 0.5s ease-in-out;
    position: relative;
    width: 30%;
    transition: transform 0.3s ease;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .card-action{
        transition:all 0.5s ease-in-out;
        position: relative;
        width: 100%;
        margin-bottom: 8px;
        transition: transform 0.3s ease;
    }
  
  }




.card-action:hover{
    transform: scale(1.05);
    cursor: pointer;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
}


.card-action:hover .card-action-head p{
    color: #fff;
}


.card-action-image-container img{
    height: 12rem;
}

.card-action-body{
    /*margin-bottom: 3rem;*/
    /* height: 10rem; */
    position: relative;
}

/* .card-action-body{
    /*margin-bottom: 3rem;
    height: 10rem;
    position: relative;
} */

.card-action-body1{
    /*margin-bottom: 3rem;*/
    height: 10rem;
    /* position: relative; */
}

.card-action-body p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; 
    overflow: hidden;
    font-weight: lighter;
  
}


.card-action .btn-container{
    position: absolute;
    bottom: 1rem;

}

.card-action-body h6{
    font-size: 14px;
    font-weight: bolder;
    color: var(--color-primary-yellow);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
    overflow: hidden;

}


.card-action-body1 h6{
    font-size: 25px;
    font-weight: bolder;
    color: var(--color-primary-yellow);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
    overflow: hidden;

}

.card-action-body h4{
    font-weight: bolder;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
    /* overflow: hidden; */
    height: 100px;
    font-size: 20px!important;    
}



.card-link{
    border-bottom: 3px solid var(--color-primary-yellow-3);
    font-weight: bold;
    font-style: 14px;
    
}